<template>
  <FocusTrap>
    <div>

      <div class="card" id="mycard">

        <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
          <div class="form-group row">
            <div class="col">
              <h5 v-if="cashier_id > 0"> {{cashier}} Bill List</h5>
              <h5 v-if="cashier_id == 0"> All Cashier Bill List</h5>
            </div>

            <label class="col-md-1 col-form-label font-weight-semibold"></label>
            <div class="col-md-2">
        <!--            <select class="form-control" v-model="cashier">-->
        <!--              <option value="0" >All</option>-->
        <!--              <option value="999" >999</option>-->
        <!--              <option v-for="user in cashiers" v-bind:value="user.name" >  {{user.name}}</option>-->
        <!--            </select>-->
            </div>

            <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="showFilter" >Show</button>
          </div>

        </div>

        <div class="table-responsive">
          <table id="bill_table"
                 class="table table-bordered table-columned"
                 data-search="false"
                 data-pagination="false"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-detail-view="true"
                 data-detail-view-icon="true"
                 data-detail-view-by-click="true"
                 data-detail-formatter="billDetailFormatter"
                 data-row-style="rowStyle"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-field="id" data-class="d-none" data-sortable="true">ID</th>
              <th data-formatter="runningFormatter" data-width="50" >#</th>
              <th data-field="doc_no" data-width="75" data-sortable="true"  >Bill No</th>
              <th data-field="bill_date" data-width="109" data-sortable="true" data-formatter="dateFormatter">Date</th>
              <th data-field="bill_date" data-width="50" data-sortable="true" data-formatter="timeFormatter">Time</th>
              <th data-field="bill_type" data-width="75" data-sortable="true" >Type</th>
              <th data-field="counter.name" data-width="125" data-sortable="true" >Counter</th>
              <th data-field="customer_name" data-sortable="true" >Customer</th>
              <th data-field="vehicle_no" data-width="135" data-sortable="true" >Vehicle</th>
              <th data-field="netamt" data-width="111" data-sortable="true" data-align="right" >Amount</th>
              <th data-field="maker" data-width="109" data-sortable="true" data-class="d-none">Cashier</th>
              <th data-field="terminal.name" data-width="109" data-sortable="true" >Device</th>

<!--              <th data-field="ora_cd" data-sortable="true" data-width="50">JBunk</th>-->
<!--              <th data-field="acc_cd" data-sortable="true" data-class="d-none">Account</th>-->

            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>

        <!-- Context Menu -->
        <div id="context-menu" class="context-table">
          <div class="dropdown-menu">
            <a href="#" class="dropdown-item" @click="loadSaleData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item"> <i class="icon-calculator4" ></i>Upload to Account</a>
          </div>
        </div>
        <!-- End of Context Menu -->

        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="275" @opened="afterShowFilter" >
          <form>
            <div class="card">
              <div class="card-header header-elements-inline" style="padding: 10px;">
                <h3 class="card-title">Sale Bill</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <a class="list-icons-item" data-action="remove" @click="closeFilterWindow"></a>
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Cashier:</label>
                  <div class="col-md-9">
                    <select id="cmbcashier" class="form-control" v-model="cashier_id">
                      <option value="0" selected >All</option>
                      <option v-for="user in cashiers" v-bind:value="user.id" >  {{user.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Date:</label>
                  <div class="col-md-4">
                    <input type="date" class="form-control"  v-model="from_date" />
                  </div>
                  <label class="col-md-2 col-form-label">To:</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control" v-model="upto_date" />
                  </div>
                </div>



                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Type:</label>
                  <div class="col-md-4">
                    <select   class="form-control" v-model="billtype" >
                      <option value="All">All</option>
                      <option value="Cash">Cash</option>
                      <option value="Cash">Credit</option>
                      <option value="Digital">Digital</option>
                      <option value="Fleet">Fleet</option>
                      <option value="Suspense">Suspense</option>
                    </select>
                  </div>

                  <label class="col-md-2 col-form-label">Shift</label>
                  <div class="col-md-3">
                    <select   class="form-control" v-model="shift_id" >
                      <option value="0">All</option>
                      <option v-for="shift in shifts" :value="shift.id">{{shift.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadSaleData" >Show Bills<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>

        </div>

      <div >
      <div class="mb-3 pt-2">
      <!--        <h6 class="mb-0 font-weight-semibold">-->
      <!--          Card navigation options-->
      <!--        </h6>-->
        <span class="text-muted d-block">Sale Summary</span>
      </div>

      <div class="row">

        <div class="col-md-2">

          <!-- Text list group -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Total</h5>
            </div>

            <!--            <ul class="list-group list-group-flush border-top">-->
            <!--              <li class="list-group-item">-->
            <!--                Sheared coasted so concurrent-->
            <!--                <span class="badge badge-dark ml-auto">New</span>-->
            <!--              </li>-->
            <!--              <li class="list-group-item">-->
            <!--                Goodness instead gull vulture-->
            <!--                <span class="badge bg-pink-400 badge-pill ml-auto">38</span>-->
            <!--              </li>-->
            <!--              <li class="list-group-item">-->
            <!--                Devilish yellow unsafe jerkily-->
            <!--                <span class="badge bg-indigo-400 ml-auto">Fixed</span>-->
            <!--              </li>-->
            <!--              <li class="list-group-item">-->
            <!--                Relentless ouch essentially-->
            <!--                <span class="badge bg-success badge-pill ml-auto">40</span>-->
            <!--              </li>-->
            <!--            </ul>-->

            <div class="card-footer d-flex justify-content-between">
              <span class="text-muted">Amount</span>
              <span>
                  <span class="text-muted ml-2">{{total_value | indianFormat}}</span>
                </span>
            </div>
          </div>
          <!-- /text list group -->

        </div>

        <div class="col-md-2">

          <!-- Text list group -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Cash</h5>
            </div>

      <!--            <ul class="list-group list-group-flush border-top">-->
      <!--              <li class="list-group-item">-->
      <!--                Sheared coasted so concurrent-->
      <!--                <span class="badge badge-dark ml-auto">New</span>-->
      <!--              </li>-->
      <!--              <li class="list-group-item">-->
      <!--                Goodness instead gull vulture-->
      <!--                <span class="badge bg-pink-400 badge-pill ml-auto">38</span>-->
      <!--              </li>-->
      <!--              <li class="list-group-item">-->
      <!--                Devilish yellow unsafe jerkily-->
      <!--                <span class="badge bg-indigo-400 ml-auto">Fixed</span>-->
      <!--              </li>-->
      <!--              <li class="list-group-item">-->
      <!--                Relentless ouch essentially-->
      <!--                <span class="badge bg-success badge-pill ml-auto">40</span>-->
      <!--              </li>-->
      <!--            </ul>-->

            <div class="card-footer d-flex justify-content-between">
              <span class="text-muted">Amount</span>
              <span>
                  <span class="text-muted ml-2 ">{{summarydata.get("Cash") | indianFormat}}</span>
                </span>
            </div>
          </div>
          <!-- /text list group -->

        </div>

        <div class="col-md-2">

          <!-- Text list group -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Credit</h5>
            </div>

            <div class="card-footer d-flex justify-content-between">
              <span class="text-muted">Amount</span>
              <span>
                  <span class="text-muted ml-2">{{summarydata.get("Credit") | indianFormat}}</span>
                </span>
            </div>
          </div>
          <!-- /text list group -->

        </div>

        <div class="col-md-2">

          <!-- Text list group -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Digital</h5>
            </div>

            <div class="card-footer d-flex justify-content-between">
              <span class="text-muted">Amount</span>
              <span>
                  <span class="text-muted ml-2">{{summarydata.get("Digital") | indianFormat}}</span>
                </span>
            </div>
          </div>
          <!-- /text list group -->

        </div>

        <div class="col-md-2">

          <!-- Text list group -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Fleet</h5>
            </div>

            <div class="card-footer d-flex justify-content-between">
              <span class="text-muted">Amount</span>
              <span>
                  <span class="text-muted ml-2">{{summarydata.get("Fleet") | indianFormat}}</span>
                </span>
            </div>
          </div>
          <!-- /text list group -->

        </div>

        <div class="col-md-2">

          <!-- Text list group -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Suspense</h5>
            </div>

            <div class="card-footer d-flex justify-content-between">
              <span class="text-muted">Amount</span>
              <span>
                  <span class="text-muted ml-2">{{summarydata.get("Suspense") | indianFormat}}</span>
                </span>
            </div>
          </div>
          <!-- /text list group -->

        </div>

      </div>

      </div>

    </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'BillView',
    store,
    data(){
      return {
        cashier_id: 0,
        shift_id:0,
        cashiers:[],
        billdata:[],
        shifts : [],
        summarydata:  new Map(),
        total_value: 0,
        total_qty: 0,
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        billtype: "All",
        viewldgr_cls: 'd-none',
      }
    },
    mounted () {
      const self = this;

      this.$data.mytable = $('#bill_table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          let code = $(row.children('*')[2]).text();

          if ($(e.target).text() === 'Refresh') {
            self.loadSaleData();
          } else if ($(e.target).text() == 'Upload to Account'  && code.length > 0 ) {

            self.exportVoucher(code);
          }
        }
      })

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#buyer-table>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadShifts();
      self.loadCashiers();
      self.showFilter();
    },
    filters:{
      billtype(val){
        switch (val) {
          case 1:
            return 'Cash';
          case 2:
            return 'Credit';
          case 3:
            return 'Card';
          case 4:
            return 'Fleet';
          case 5:
            return 'Suspense';
        }
      },
      indianFormat(val){
        try{
          return val.toFixed(2);
        }catch (e) {
        }

        return '';
      },
      dateFormat(val){
        try {
          return val.slice(0, 10).split('-').reverse().join('-');
        } catch (ex) {

        }
        return '';
      },
    },
    methods:{
      showFilter () {
        this.$modal.show('filter-window')
      },
      afterShowFilter(){
        $('#cmbcashier').focus();
      },
      closeFilterWindow() {
        this.$modal.hide('filter-window');
      },
      loadSaleData(){

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        let query = `cashier_id=${self.$data.cashier_id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&billtype=${self.$data.billtype}`;

        // alert(`${process.env.VUE_APP_ROOT_API}api/xpos/bills?${query}`);
        self.$data.total_value = 0;
        self.$data.billdata = [];
        self.$data.summarydata  = new Map();
        self.$modal.hide('filter-window');
        self.$data.mytable.bootstrapTable('load', []);

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/bills?${query}`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {

              if (_.isArray(resp.data)) {

                self.$data.mytable.bootstrapTable('load', resp.data);

                self.$data.total_value = 0;

                self.$data.summarydata  = new Map();

                resp.data.forEach(function (det) {

                  if(det.status == "Active" ) {

                    self.$data.total_value += parseFloat(det.netamt);

                    if(self.$data.summarydata.has(det.bill_type)) {
                      self.$data.summarydata.set(det.bill_type, (self.$data.summarydata.get(det.bill_type) + parseFloat(det.netamt) ) );
                    } else {
                      self.$data.summarydata.set(det.bill_type, parseFloat(det.netamt));
                    }

                  }
                });


              }


          } else {
            swal({ title: 'Oops!', text: err.msg, type: 'error' });
          }

        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
        }).finally(function () {
          $('#mycard').unblock();
        });
      },
      loadCashiers () {
        const self = this;
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        self.$data.cashiers = [];

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashiers`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.cashiers = resp.data;
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      },
      loadShifts () {
        const self = this;
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        self.$data.shifts = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/shifts/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {


          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.shifts = resp.data;
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      },
      exportVoucher (code) {
        const self = this;

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: userService.authHeader(),
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        fetch(`${process.env.VUE_APP_ROOT_API}api/v1/pos/bill/export/${code}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({title: 'Exported', type: 'success', text: resp.msg });
            // self.$emit('contra_saved',resp.data);
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock();
        });
      },
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
